const language = localStorage.getItem("language");
const cssDirectory = language === "ar" ? "css-rtl" : "css";

export const DomainName = "api.modesignsstudio.com";
export const apiEndpoint = "https://api.modesignsstudio.com/api";
export const firebaseInitConfig = {
  apiKey: "AIzaSyCCRsci3cGwDr88sgV2cm86BudM5mJIFQc",
  authDomain: "realestate-40ff1.firebaseapp.com",
  projectId: "realestate-40ff1",
  storageBucket: "realestate-40ff1.appspot.com",
  messagingSenderId: "703391828421",
  appId: "1:703391828421:web:f45de36830dae596a663ac",
  // measurementId: "G-RLKZVXW08K",
};

export const colorsPalette = {
  primary: "#1f223e",
  secondary: "#95c41f",
  pine: "#006064",
  candy: "#FF1743",
  skyBlue: "#2395ec",
  deepWarning: "#ff7f00",
  mint: "#4caf50",
  cream: "#e0e0e0",
  lightBlue: "#90caf9",
  rose: "#f48fb1",
};

const MapApiKey = "AIzaSyCCRsci3cGwDr88sgV2cm86BudM5mJIFQc";

export const resources = {
  animateCss:
    "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css",
  bootstrapSelectPicker: "/assets/js/custom-js/bootstrap-select.js",
  selectpickerLauncher: "/assets/js/custom-js/selectpicker-launcher.js",
  selectpickerStyle: "assets/" + cssDirectory + "/custom/select-picker.css",
  mapApiKey: MapApiKey,
  mapPollyfill: "https://polyfill.io/v3/polyfill.min.js?features=default",
  mapPlaces:
    "https://maps.googleapis.com/maps/api/js?key=" +
    MapApiKey +
    "&callback=initMap&libraries=places&v=weekly&language=" +
    language +
    "&region=EG",
  mapLauncher: "/assets/js/custom-js/google-maps-launcher.js",
  firebase: "https://www.gstatic.com/firebasejs/6.0.2/firebase.js",
  customSelect: "assets/js/custom-js/custom-select.js",
};
